import React from "react"
import { Link, graphql } from "gatsby"
import { Spring } from "react-spring/renderprops";
import VisibilitySensor from '../components/visibilitySensor'
import Layout from "../layouts/layout"
import SEO from "../components/seo"

const AboutPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
    return (
      <Layout location={location} title={siteTitle}>
        <SEO title="Who we are at Sunset" />
        <section className="uni-section about-hero">
            <div className="uni-container flex-space-between flex-vertical-reverse">
                <div className="uni-col">
                    <h1 className="uni-h1">Sunset empowers you to take control of your burnout</h1>
                    <p className="uni-hero-p">We are passionately, tirelessly, dedicated to helping you improve your health, mental wellness, and resilience.</p>
                </div>
                <img src={require('../../content/assets/hero-content/about.svg')} alt="About us image" className="uni-hero_img about-us_img" />
            </div>
        </section>
        <section className="uni-section bg-f0f6f9">
            <div className="uni-container flex-space-between flex-vertical">
              <div className="uni-col about-col">
                <img src={require('../../content/assets/splash/about-us.jpg')} className="clinical_img"/>
              </div>
              <div className="uni-col about-col about-quote">
                  <h2 className="uni-h2">How We Got Here</h2>
                  <p className="uni-p">
                    Sunset Health was founded by a team of digital health veterans that have over 40 years in combined 
                    experience starting and building healthcare focused technology companies. Over the course of our 
                    careers, we’ve had deeply personal experiences witnessing and battling burnout.
                  </p>
                  <br/>
                  <p>
                    Drawing on our collective experiences, we built a company that gives people the tools and skills 
                    needed to assess and cope with the stressors that lead to burnout, isolation, depression, and anxiety. 
                    A tool that would bring expert guidance from licensed medical professionals directly to your phone. 
                    Your tools will be accessible anytime, anywhere.
                  </p>
              </div>
            </div>
        </section>
        <section className="uni-section">
            <div className="uni-container">
              <div className="uni-h-wrap">
                <h2>Meet the clinical team behind Sunset</h2>
              </div>
              <div className="clinical-team-wrap">
                <div className="uni-col about-col clinical-col">
                  <p>
                    Our clinical team is comprised of medical health professionals, consisting of leading psychologists, psychiatrists, and mental health specialists. 
                    We’re keenly aware that there is no one size fits all solution to behavioral health. We’ve worked with experts across over 50 different specialities 
                    to understand and develop recovery plans that compose a wholistic approach to treatment.
                  </p>
                  {/* <ul className="ind-pricing-feature-list about-doctors-list">
                    <li>Pyschotherapists</li>
                    <li>Psychologists</li>
                    <li>Doctors</li>
                    <li>Behavioral Specialists</li>
                    <li>Nurses</li>
                  </ul> */}
                </div>
                <div className="uni-col about-col clinical_img">
                  <img src={require('../../content/assets/splash/about-clinical.jpg')} />
                </div>
                {/* <VisibilitySensor partialVisibility once>
                  {({ isVisible }) => (
                    <Spring delay={200} to={{ opacity: isVisible ? 1 : 0, transform: isVisible ? "translateY(0)" : "translateY(200px)", }}>
                      {({opacity, transform}) => (
                        <div className="uni-col about-col clinical-why" style={{opacity, transform}}>
                          <h3>Why we choose to focus on mental wellness </h3>
                          <p>
                          The reality is that you’re not alone and that a growing number of people have been feeling this way.
                          </p>
                          <p>This largely impacts your relationships, phyiscal health, and personal well-being and left untreated, can lead to depression, 
                            substance use, and the lasting effects of psychological trauma.</p>
                        </div>
                      )}
                    </Spring>
                  )}
                </VisibilitySensor>
                <VisibilitySensor partialVisibility once>
                  {({ isVisible }) => (
                    <Spring delay={200} to={{ opacity: isVisible ? 1 : 0, transform: isVisible ? "translateY(0)" : "translateY(200px)", }}>
                      {({opacity, transform}) => (
                        <div className="uni-col about-col clinical-results" style={{opacity, transform}}>
                          <h3>How we plan to change the game</h3>
                          <p>
                            Our programs focus on helping you build stronger relationships, higher levels of self-esteem, and also the 
                            ability to remember what’s important to you in life. The combination of these small, science-based, daily actions can help anyone cope 
                            with and prevent the symptoms of burnout before our users realize it.
                          </p>
                        </div>
                      )}
                    </Spring>
                  )}
                </VisibilitySensor> */}
              </div>
            </div>
        </section>
        {/* <section className="uni-section bg-5590B4">
            <div className="uni-h-wrap quote-wrap">
                <img src={require('../../content/assets/icons/left-quotes.svg')} className="quote_img" />
                <h3 className="emp-font-extrabold uni-banner-text quote-text">It's never too early or too late to start</h3>
                <img src={require('../../content/assets/icons/right-quotes.svg')} className="quote_img" />
            </div>
        </section> */}
        <section className="uni-section border">
            <div className="uni-container">
                <div className="uni-h-wrap">
                  <h2 className="uni-h2 text-align-center">Our approach to digital therapy</h2>
                  <p className="uni-p text-align-center">We realize the world is in need of a solution that can effectively help people cope with isolation, anxiety and burnout now more than ever. </p>
                  <br />
                </div>
                <div className="ind-about-wrap">
                  <VisibilitySensor partialVisibility>
                    {({ isVisible }) => (
                      <Spring delay={100} to={{ opacity: isVisible ? 1 : 0, transform: isVisible ? "translateX(0)" : "translateX(-200px)", }}>
                        {({opacity, transform}) => (
                          <div className="ind-about-item" style={{opacity}}>
                            <img src={require('../../content/assets/icons/employees.png')} className="ind-about_img" />
                            <div className="text-box-hold about-text-box">
                              <h4 className="emp-h4 ind-values-h3">Users come first</h4>
                              <p className="uni-p ind-values-p">From design to treatment plans to customer support, everything we do is centered around our users needs.</p>
                            </div>
                          </div>
                        )}
                      </Spring>
                    )}
                  </VisibilitySensor>
                  <VisibilitySensor partialVisibility>
                    {({ isVisible }) => (
                      <Spring delay={200} to={{ opacity: isVisible ? 1 : 0, transform: isVisible ? "translateX(0)" : "translateX(-200px)", }}>
                        {({opacity, transform}) => (
                          <div className="ind-about-item" style={{opacity}}>
                            <img src={require('../../content/assets/icons/data-analytics.png')} className="ind-about_img" />
                            <div className="text-box-hold about-text-box">
                              <h4 className="emp-h4 ind-values-h3">We stand together</h4>
                              <p className="uni-p ind-values-p">We understand changing behavior can be tough, which is why we stick with, and coach, our users through the difficult process.</p>
                            </div>
                          </div>
                        )}
                      </Spring>
                    )}
                  </VisibilitySensor>
                  <VisibilitySensor partialVisibility>
                    {({ isVisible }) => (
                      <Spring delay={200} to={{ opacity: isVisible ? 1 : 0, transform: isVisible ? "translateX(0)" : "translateX(-200px)", }}>
                        {({opacity, transform}) => (
                          <div className="ind-about-item" style={{opacity}}>
                            <img src={require('../../content/assets/icons/care.png')} className="ind-about_img" />
                            <div className="text-box-hold about-text-box">
                              <h4 className="emp-h4 ind-values-h3">Rely on evidence</h4>
                              <p className="uni-p ind-values-p">We continually validate our treatments and our approach and are constantly focused on perpetual learning.</p>
                            </div>
                          </div>
                        )}
                      </Spring>
                    )}
                  </VisibilitySensor>
                </div>
            </div>
        </section>
      </Layout>
    )
}

export default AboutPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
  }
`
